import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./pages/Router";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.scss";

export default function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ChakraProvider>
          <Router />
        </ChakraProvider>
      </div>
    </BrowserRouter>
  );
}
